import React, { useEffect, useRef } from 'react'
import '../../../styles/Overview.css';

import Plyr from 'plyr';
import 'plyr/dist/plyr.css'

function Overview() {
    const videoRef = useRef(null);
    useEffect(() => {
        const player = new Plyr(videoRef.current);

    }, [])
    return (
        <div className='Overview'>
            <div className="container">
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                        <h2>Company Overview</h2>

                        <p>
                            Whitefield Door Locks, an efficient team that combines transparency, security, elegance, and trends to fit your buildings and homes seamlessly. Our company is devoted to delivering premium-quality door locks that effortlessly meet advanced technology with timeless design. Our inception was fueled by a vision to transcend conventional door lock offerings.
                            <br></br>
                            <br></br>
                            In 2002, our journey began in Valiyangadi, within Big Bazar, marking the inception of our enterprise. However, driven by ambition and a vision for growth, we later transitioned to our present location at Door No: 9/605, Opposite Syndicate Bank, Cherooty Road, Cherooty Road-673001. This strategic relocation paved the way for an extensive expansion of our offerings and services. Today, we're serving a diverse clientele, providing an array of hardware products such as digital locks, door handles, and modular kitchen accessories, among others. Our commitment to excellence and customer satisfaction remains steadfast as we continue to evolve and innovate in the industry.
                            <br></br>
                            <br></br>
                            Through years of commitment, dedication, and continuous innovation, Whitefield Door Locks has evolved into a trusted name in the industry, offering a diverse array of door security solutions that embody both functionality and style.
                        </p>
                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                        <video ref={videoRef} controls playsinline >
                            <source src="video/whitefield.mp4" type="video/mp4" />
                        </video>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Overview