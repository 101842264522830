

const BannerData= {
    AboutH2: "About",
    AboutImg: "img/wooden-door-open-into-modern-hotel-room-closeup.jpg",
    ProductsH2:"Products",
    ProductsImg:"img/wooden-door-open-into-modern-hotel-room-closeup.jpg",
    BlogH2:"Blogs",
    BlogImg:"img/wooden-door-open-into-modern-hotel-room-closeup.jpg",
    GalleryH2:"Gallery",
    GalleryImg:"img/wooden-door-open-into-modern-hotel-room-closeup.jpg",
    HowItworksH2:"How It works",
    HowItworksImg:"img/wooden-door-open-into-modern-hotel-room-closeup.jpg",
    ContactH2:"Contact",
    ContactImg:"img/wooden-door-open-into-modern-hotel-room-closeup.jpg",
}

export default BannerData