import React from 'react'
import '../../../styles/Mission.css'

function Mission() {
    return (
        <div className='Mission'>
            <div className="container">
                <div className="row">
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="100">
                        <img src="img/mission.png" alt="our mission" />
                        <h3>Mission</h3>
                        <p> Ensuring security and peace of mind, Whitefield Door Locks is committed to providing
                            innovative and reliable locking solutions that safeguard homes and businesses.
                        </p>

                    </div>
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="300">
                        <img src="img/vision.png" alt="our vision" />
                        <h3>Vision</h3>
                        <p> To be a global leader in the security industry, recognized for our advanced technology,
                            exceptional quality, and dedication to customer safety.</p>

                    </div>
                    <div className="card" data-aos="fade" data-aos-duration="1000" data-aos-delay="500">
                        <img src="img/value.png" alt="Our Values" />
                        <h3>Our Values</h3>
                        <p> Integrity guides our actions, excellence drives our innovation, and customer-centricity defines
                            our relationships. We prioritize trust, quality, and continuous improvement in all aspects of our
                            operations.
                        </p>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Mission