import React from 'react';
import '../../../styles/BannerImgs.css';

function BannerImgs() {
  return (
    <div className='BannerImgs'>
        <div className="img-container">
            <img src="img/banner-imgs/deco.png" alt="deco"data-aos="fade-down" data-aos-duration="1000" />
        </div>
        <div className="img-container">
            <img src="img/banner-imgs/hafele.png" alt="hafele"data-aos="fade-down" data-aos-duration="1000"data-aos-delay="200" />
        </div>
        <div className="img-container">
            <img src="img/banner-imgs/hettich.png" alt="hettich"data-aos="fade-down" data-aos-duration="1000"data-aos-delay="400" />
        </div>
        <div className="img-container">
            <img src="img/banner-imgs/ozone.png" alt="ozone"data-aos="fade-down" data-aos-duration="1000" data-aos-delay="600"/>
        </div>
    </div>
  )
}

export default BannerImgs