import React, { useEffect, useState } from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Helpers/BannerData'
import axios from 'axios'
import { wordpressUrl } from '../Helpers/wordpressUrl'
import GalleryList from '../Components/GalleryPage/Gallery/GalleryList'
import Footer from '../Components/General_cmpnts/Footer/Footer'
import { Helmet } from 'react-helmet'

function Gallery() {
  const { GalleryH2, GalleryImg } = BannerData;
  const [gallerydata, setgallery] = useState([]);

  useEffect(() => {

    const id = 45
    axios.get(`${wordpressUrl}wp-json/wp/v2/gallery/${id}`)
      .then(res => {
          setgallery(res.data)
          // console.log(res.data + 'gallery array')
      }).catch(err => {
        console.log(err)
      })
  }, [])
  return (
    <div className='Gallery'>
      <Helmet>
        <title>Gallery - WhiteField Gallery</title>
        <meta name="description" content="Discover the elegance and security of Whitefield Door Locks through our gallery. Explore a collection of high-quality door lock solutions for your space." />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Gallery - WhiteField Gallery"></meta>
        <meta property="og:description" content="Discover the elegance and security of Whitefield Door Locks through our gallery. Explore a collection of high-quality door lock solutions for your space."></meta>
      </Helmet>
      <Navbar />
      <BannerSection h2={GalleryH2} imgUrl={GalleryImg} />
      <GalleryList gallery={gallerydata} />
      <Footer />


    </div>
  )
}

export default Gallery