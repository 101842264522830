import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import MenuContext from '../../../Context/menuProvider';
import '../../../styles/Footer.css'
function Footer() {
    const { setImage, image } = useContext(MenuContext)

    return (
        <div className='Footer'>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="logo">
                            <img src={image} alt="WhiteField logo" />

                        </div>
                        <p className='quote'>Builders Hardware, Beyond Compare!</p>
                        <div className="social-media-icons">
                            <a href="https://www.facebook.com/whitefieldgallery/" target="_blank">
                                <i class="fa-brands fa-facebook"></i>

                            </a>
                            <a href="https://www.instagram.com/whitefield__gallery?igsh=enl5aWE4cGZyd2l4" target="_blank"
                                rel="noopener noreferrer">
                                <i class="fa-brands fa-instagram"></i>

                            </a>
                            <a href="https://youtube.com/@whitefiel?si=xFz0oDXeiwVgvUqD" target="_blank"
                                rel="noopener noreferrer">
                                <i class="fa-brands fa-youtube"></i>
                            </a>
                            
                        </div>

                    </div>
                    <div className="col">

                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>

                        </li>
                        <li>
                            <Link to="/products">Products</Link>
                        </li>





                    </div>
                    <div className="col">
                        <li>
                            <Link to="/gallery">Gallery</Link>
                        </li>
                        <li>
                            <Link to="/blogs">Blogs</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </div>
                    <div className="col right">
                        <div className="icon">
                            <i className="fa-solid fa-location-dot"></i>
                        </div>
                        <div className="content">
                            <p>
                                Cherooty Rd, opposite Syndicate Bank Building,<br /> Big Bazaar, Mananchira,<br /> Kozhikode, Kerala,<br /> 673001
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="copyright">
                <p>© Copyright - WhiteField</p>
            </div>
        </div>
    )
}

export default Footer