import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Products from './Pages/Products';
import Gallery from './Pages/Gallery';
import Blogs from './Pages/Blogs';
import ProductSinglePg from './Pages/ProductSinglePg';
import BlogSinglePg from './Pages/BlogSinglePg';
import ScrollToTop from './Components/General_cmpnts/ScrollToTop/ScrollToTop';
import Contact from './Pages/Contact';
import Whatsapp from './Components/General_cmpnts/Whatsapp_btn/Whatsapp';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
        <Whatsapp/>
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/products' element={<Products />} />
          <Route path='/products/:slug' element={<ProductSinglePg />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/blogs' element={<Blogs/>} />
          <Route path='/blogs/:slug' element={<BlogSinglePg/>} />
          <Route path='/contact' element={<Contact/>} />

          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
