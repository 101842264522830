import React, { useState, useRef, useEffect } from 'react';
import '../../../styles/ProductDetails.css';

function ProductDetails({ product }) {
    const [prod_img, setProd_img] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const extractImageLinksFromContent = (content) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');
            const imageElements = doc.querySelectorAll('img');

            const imageLinks = Array.from(imageElements).map((img) => {
                return img.getAttribute('src');
            });

            return imageLinks.filter((link) => link !== null);
        };


        if (product && product.acf && product.acf.product_images) {
            const imageLinks = extractImageLinksFromContent(product.acf.product_images);
            setProd_img(imageLinks);
            setLoading(false);


        }
    }, [product]);

    const [img, setImg] = useState(prod_img.length > 0 ? prod_img[0] : null);

    const refs = useRef([]);

    const hoverHandler = (image, i) => {
        setImg(image);
        refs.current[i].classList.add('active');
        for (var j = 0; j < prod_img.length; j++) {
            if (i !== j) {
                refs.current[j].classList.remove('active');
            }
        }
    };

    const addRefs = (el) => {
        if (el && !refs.current.includes(el)) {
            refs.current.push(el);
        }
    };

    if (loading) {
        return (
            <div className="spinning_loading">
                <div className="lds-grid">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

    return (
        <div className="ProductDetails">
            {product && (
                <div className="container">
                    <div className="row">
                        <div className="left-col">
                            <div className="content">
                                {product.title.rendered && (
                                    <h2 className="title">{product.title.rendered}</h2>
                                )}
                                <div className="brand">
                                    <span>Brand: </span>
                                    {product.acf.brand_link && product.acf.brand_name && <a href={product.acf.brand_link}>{product.acf.brand_name}</a>}
                                </div>

                            </div>
                            <div className="img-container">
                                <div className="img-grid">
                                    {prod_img.map((image, i) => (
                                        <div
                                            className={i === 0 ? 'img_wrap active' : 'img_wrap'}
                                            key={i}
                                            onMouseOver={() => hoverHandler(image, i)}
                                            ref={addRefs}
                                        >
                                            <img src={image} alt="" />
                                        </div>
                                    ))}
                                </div>
                                <div className="img-display">
                                    {img ? (
                                        <img src={img} alt="Product Images" />
                                    ) : (

                                        <img src={prod_img[0]} alt="" />
                                    )}
                                </div>
                            </div>


                        </div>
                        <div className="right-col">
                            <h3>Description</h3>
                            <p>{product.acf && product.acf.description && <div dangerouslySetInnerHTML={{ __html: product.acf.description }}></div>}</p>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProductDetails;
