import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { wordpressUrl } from '../Helpers/wordpressUrl'
import MenuContext from '../Context/menuProvider'
import ProductDetails from '../Components/ProductsPage/ProductDetails/ProductDetails'
import ShareButton from '../Components/General_cmpnts/ShareButton/ShareButton'
import Footer from '../Components/General_cmpnts/Footer/Footer'
import { Helmet } from 'react-helmet'

function ProductSinglePg() {
    const { setImage, handleNavbar, image, setnavbar } = useContext(MenuContext);
    const { slug } = useParams();
    const [Product, setProduct] = useState(null);

    useEffect(() => {
        if (window.scrollY > 200) {
            setImage('../../img/Whitefield-gallery.png')
        } else {
            setImage('../../img/Whitefield-gallery.png')
        }
    })
    useEffect(() => {
        axios.get(`${wordpressUrl}wp-json/wp/v2/products?slug=${slug}`)
            .then(res => {
                if (res.data.length > 0) {
                    setProduct(res.data[0])
                    console.log(Product + 'Product array')
                } else {
                    console.log('No Products found')
                }
            }).catch(err => {
                console.log(err)
            })
    }, [setImage, slug])


    return (
        <div className='ProductSinglePg'>
            <Helmet>
                <title>{Product && Product.title.rendered}</title>
                {Product && Product.acf.meta_description && <meta name="description"
                    content={Product && Product.acf.meta_description}></meta>}
                <meta property="og:locale" content="en_US"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content={Product && Product.title.rendered}></meta>
                {Product && Product.acf.meta_description && <meta property="og:description" content={Product && Product.acf.meta_description}></meta>}
                <meta property="og:site_name" content="That Marketing Guy"></meta>
            </Helmet>
            <Navbar />
            <ProductDetails product={Product} />
            <ShareButton />
            <Footer />


        </div>
    )
}

export default ProductSinglePg