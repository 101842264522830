import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Helpers/BannerData';
import ProductsIntro from '../Components/ProductsPage/ProductsIntro/ProductsIntro';
import ProductList from '../Components/ProductsPage/ProductsList/ProductList';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import { Helmet } from 'react-helmet';

function Products() {
  const { ProductsH2, ProductsImg } = BannerData;

  return (
    <div className='Products'>
      <Helmet>
        <title>Products - WhiteField Gallery</title>
        <meta name="description" content="Explore a diverse range of premium door lock products at Whitefield. Elevate your security with our high-quality, reliable, and stylish door lock solutions." />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Products - WhiteField Gallery"></meta>
        <meta property="og:description" content="Explore a diverse range of premium door lock products at Whitefield. Elevate your security with our high-quality, reliable, and stylish door lock solutions."></meta>
      </Helmet>
      <Navbar />
      <BannerSection h2={ProductsH2} imgUrl={ProductsImg} />
      <ProductsIntro />
      <ProductList />
      <Footer />

    </div>
  )
}

export default Products