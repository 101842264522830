import React, { useEffect } from 'react'
import '../../../styles/Testimonials.css'
import Flickity from 'flickity';
import 'flickity/css/flickity.css';


function Testimonials() {
    useEffect(() => {
        const flickityCarousel = new Flickity('.main-carousel', {
            cellAlign: 'left',
            contain: true,
            draggable: true,
            wrapAround: true,
            freeScroll: true,
            prevNextButtons: true,
            autoPlay: true,
            selectedAttraction: 0.01,
            friction: 0.20,
            // groupCells: 2,
            imagesLoaded: true,
            pauseAutoPlayOnHover: false,

        });

        return () => {
            flickityCarousel.destroy();
        };
    }, []);
    return (
        <div className='Testimonials'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Testimonials</h2>
                <div className="main-carousel">

                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>It is very good showroom. All the models are displayed there. So there are a lot of models that are easy to select, kitchen display, bedroom lock, wardrobe handle and so on, customer service as well.</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/ABDUL MAHAROOF BAVA.png" alt="ABDUL MAHAROOF BAVA" />
                            <h3>Abdul Maharoof Bava</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>Variety of selections and quality products.Both staffs and management people are very good. Excellent customer support! Great shopping experience!</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/avatar.jpeg" alt="jashi" />
                            <h3>Jashi beypore</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>It was nice experiance to purchase in White field gallery calicut, good customer service and providing quality products with reasonable price. Just go for it..</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/avatar.jpeg" alt="azeez" />
                            <h3>Farhana Azeez</h3>

                        </div>
                    </div>
                    <div className="carousel-cell">
                        <div className="card">
                            <span className='quote'><i class="fa-solid fa-quote-left"></i></span>
                            <div className="content">
                                <p>A really nice showroom to purchase fittings according to your budget. They've got products in all ranges. You can learn everything about products too. Kudos to Mr. Mehroof who explained everything well.</p>
                            </div>
                            <div className="five-star-icon">
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                                <span><i class="fa-solid fa-star"></i></span>
                            </div>
                            <img src="img/testimonials/avatar.jpeg" alt="asla" />
                            <h3>Asla Ali</h3>

                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default Testimonials