import React, { useEffect, useState, useRef } from 'react';
import '../../../styles/FeaturedProducts.css';
import { wordpressUrl } from '../../../Helpers/wordpressUrl';
import axios from 'axios';
import Flickity from 'flickity';
import 'flickity/css/flickity.css';
import { Link } from 'react-router-dom';

function FeaturedProducts() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const flickityRef = useRef(null);
  const perPage = 100;
  const page = 1; // You may adjust the page as needed

  const flickityOptions = {
    initialIndex: 0,
    wrapAround: true,
    cellAlign: 'left',
    contain: true,
    draggable: true,
    freeScroll: true,
    prevNextButtons: true,
    autoPlay: true,
    selectedAttraction: 0.01,
    friction: 0.20,
    imagesLoaded: true,
    pauseAutoPlayOnHover: false,
    pageDots: true,
  };

  const fetchProducts = () => {
    axios.get(`${wordpressUrl}wp-json/wp/v2/products?per_page=${perPage}&page=${page}`)
      .then((res) => {
        const filteredProducts = res.data.filter((product) => product.acf.featured_products === 'Yes');
        setProducts(filteredProducts);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error('Error fetching products:', err);
      });
  };

  useEffect(() => {
    fetchProducts();

  }, []);

  useEffect(() => {

    const flickityElement = document.querySelector('.main-carousel');
    flickityRef.current = new Flickity(flickityElement, flickityOptions);

  }, [products]);

  if (loading) {
    return (
      <div className="spinning_loading">
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="FeaturedProducts">
      <div className="container">
        <h2 data-aos="fade" data-aos-duration="1000">Featured Products</h2>
        <div className="row">
          <div className="main-carousel">
            {products.map((product) => (
              <div key={product.id} className="carousel-cell">
                <div className="card" key={product.id} data-aos="fade" data-aos-duration="1000">
                  <Link to={`/products/${product.slug}`}>
                    <div className="img-container">
                      {product.acf.cover_image && (
                        <img src={product.acf.cover_image.sizes.medium_large} alt={product.acf.name} />
                      )}
                    </div>
                    <div className="content">
                      <h3>{product.title.rendered}</h3>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div className="button">
            <a href="/products" className='view-more'><span>View More</span></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedProducts;
