import React from 'react'
import  '../../../styles/BlogIntro.css'

function BlogIntro() {
    return (
        <div className='BlogIntro'>
            <div className="container">
                <p data-aos="fade" data-aos-duration="1000">
                Welcome to our blog, where curiosity meets information and ideas come to life. Here, we embark on a journey of exploration, delving into diverse topics that inspire, educate, and entertain. From the latest trends in technology to insightful tips on personal development, our blog is a digital canvas painted with a rich tapestry of knowledge. Join us as we navigate through the realms of innovation, creativity, and thought-provoking discussions. Whether you're seeking practical advice, thought-provoking insights, or a dose of inspiration, our blog is your gateway to a world of captivating content. Let the adventure begin!
                </p>
            </div>
        </div>
    )
}

export default BlogIntro