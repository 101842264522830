import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import Blog_HeroSection from '../Components/BlogPage/BlogIndvl_Herosection/Blog_HeroSection'
import { wordpressUrl } from '../Helpers/wordpressUrl';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import BlogDetails from '../Components/BlogPage/BlogDetails/BlogDetails';
import ShareButton from '../Components/General_cmpnts/ShareButton/ShareButton';
import MenuContext from '../Context/menuProvider';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import { Helmet } from 'react-helmet';


function BlogSinglePg() {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const { setImage, handleNavbar, image, setnavbar } = useContext(MenuContext);


    useEffect(() => {
        if (window.scrollY > 200) {
            setImage('../../img/Whitefield-gallery.png')
        } else {
            setImage('../../img/Whitefield-gallery.png')
        }
    })

    useEffect(() => {

        axios.get(`${wordpressUrl}wp-json/wp/v2/blog?slug=${slug}`)
            .then(res => {
                if (res.data.length > 0) {
                    setBlog(res.data[0]);
                    console.log(res.data[0])
                    console.log(blog + 'blog array')
                } else {
                    console.log('No blog post found');
                }
            })
            .catch(error => {
                console.error('Error fetching blog post:', error);

            })

    }, [slug])
    return (
        <div className='BlogSinglePg'>
            <Helmet>
                <title>{blog && blog.title.rendered}</title>
                {blog && blog.acf.meta_description && <meta name="description"
                    content={blog && blog.acf.meta_description}></meta>}
                <meta property="og:locale" content="en_US"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content={blog && blog.title.rendered}></meta>
                {blog && blog.acf.meta_description && <meta property="og:description" content={blog && blog.acf.meta_description}></meta>}
                <meta property="og:site_name" content="That Marketing Guy"></meta>
            </Helmet>
            <Navbar />
            <Blog_HeroSection blogs={blog} />
            <BlogDetails blogs={blog} />
            <ShareButton />
            <Footer />


        </div>
    )
}

export default BlogSinglePg