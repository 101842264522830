import React from 'react'
import '../../../styles/Map.css'

function Map() {
  return (
    <div className='Map'>
      <div className="container">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.150672289268!2d75.7738272737029!3d11.25032445027656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba6597f4db673dd%3A0x14489a23dfad2b02!2sWhitefield%20Gallery!5e0!3m2!1sen!2sin!4v1702292995553!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}

export default Map