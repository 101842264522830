import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../../../styles/GetInTouch.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReCAPTCHA from "react-google-recaptcha";

function GetInTouch() {

    const form = useRef();

    const [verified, setVerified] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
        setVerified(true);
    }
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_atsk33x', 'template_p0mht1s', form.current, 'jqVvreCcBniUKdk3Y')
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                toast.success('Message Sent! Your message has been sent successfully.');
                // Reset the form fields after successful submission
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            }, (error) => {
                console.log(error.text);
                toast.error('An error occurred. Please try again!');
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className='GetInTouch'>
            <div className="container">
                <h2 data-aos="fade" data-aos-duration="1000">Get In Touch!</h2>
                <div className="row">
                    <div className="col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                        <h3>Give Us A Call</h3>
                        <div className="text">
                            <span><i className="fa-solid fa-phone"></i></span><a href="tel:08129774466"><p>08129774466</p></a>
                        </div>

                        <h3>Send Us An Email</h3>
                        <div className="text">

                            <span><i className="fa-solid fa-envelope"></i></span><a href="mailto:admin@whitefieldindia.com"><p>admin@whitefieldindia.com</p></a>
                        </div>

                        <h3>Location</h3>
                        <div className="text">
                            <span><i className="fa-solid fa-location-dot"></i></span><p className='address'>Cherooty Rd, opposite Syndicate Bank Building,<br /> Big Bazaar, Mananchira, <br />Kozhikode, Kerala <br />673001</p>

                        </div>
                    </div>
                    <div className="col form custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                        <form ref={form} onSubmit={sendEmail}>
                            <div>
                                <input type="text" id="name" name='name' value={formData.name} onChange={handleInputChange} placeholder='Name' required title="Enter your full name(maximum 30 characters)" maxLength={30} />
                            </div>
                            <div>
                                <input type="email" id="email" name='email' value={formData.email} onChange={handleInputChange} placeholder='Email' />
                            </div>
                            <div>
                                <input type="text" id="phone" name='phone' value={formData.phone} onChange={handleInputChange} placeholder='Phone' pattern="[0-9]{10}" title="Please enter a valid 10-digit mobile number" />
                            </div>
                            <div>
                                <textarea id="message" name='message' value={formData.message} onChange={handleInputChange} placeholder='Message' rows={4} maxLength={500} title="Enter your message (maximum 500 characters)" />
                            </div>
                            <ReCAPTCHA
                                sitekey="6LdZW4spAAAAAC-uanlnddecJsArBywrwDHb5Ktk"
                                onChange={onChange}
                            />
                            <button type="submit" disabled={!verified} value="Send"><span>Submit</span></button>

                        </form>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    )
}

export default GetInTouch