import React, { useEffect, useState } from 'react'
import '../../../styles/ProductList.css'
import axios from 'axios'
import { wordpressUrl } from '../../../Helpers/wordpressUrl'
import { Link } from 'react-router-dom'

function ProductList() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fetchProductsIds, setFetchedProductsIds] = useState(new Set());
    const [page, setPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [filteredArray, setFilteredArray] = useState([]);

    const perPage = 100;
    const lastPageIndex = page * perPage;

    const fetchProducts = () => {

        axios.get(`${wordpressUrl}wp-json/wp/v2/products?per_page=${perPage}&page=${page}`)
            .then((res) => {
                const newProducts = res.data.filter((product) => !fetchProductsIds.has(product.id));
                setProducts((prevProducts) => [...prevProducts, ...newProducts]);
                setFilteredArray((prevProducts) => [...prevProducts, ...newProducts]);
                newProducts.forEach((product) => fetchProductsIds.add(product.id));
                console.log(filteredArray)
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);

            })
    }

    useEffect(() => {
        fetchProducts();
    }, [page])

    const filterByCategory = (category) => {
        if (category === 'All') {
            setFilteredArray(products);
        } else {
            const filtered = products.filter(product => product.acf.category === category);
            setFilteredArray(filtered);
        }
        setSelectedCategory(category);
    };

    const handleLoadMore = () => {
        setPage((prevPage) => prevPage + 1);
        fetchProducts(); // Fetch new blogs when the page button is clicked
    };

    if (loading) {
        return (
            <div className='loading-container'>
                <div class="lds-grid">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }
    return (
        <div className='ProductList'>
            <div className="container">
                <div className="category-buttons">
                    <button onClick={() => filterByCategory('All')} className={selectedCategory === 'All' ? 'active' : 'not-active'}>All</button>
                    <button onClick={() => filterByCategory('Door locks')} className={selectedCategory === 'Door locks' ? 'active' : 'not-active'}>Door Locks</button>
                    <button onClick={() => filterByCategory('Kitchen accessories')} className={selectedCategory === 'Kitchen accessories' ? 'active' : 'not-active'}>Kitchen Acessories</button>
                    <button onClick={() => filterByCategory('Cabinet Handles, Knobs and Pullers')} className={selectedCategory === 'Cabinet Handles, Knobs and Pullers' ? 'active' : 'not-active'}>Cabinet Handles, Knobs and Pullers</button>
                    <button onClick={() => filterByCategory('Main Door Handles')} className={selectedCategory === 'Main Door Handles' ? 'active' : 'not-active'}>Main Door Handles</button>
                    <button onClick={() => filterByCategory('Wardrobe Accessories')} className={selectedCategory === 'Wardrobe Accessories' ? 'active' : 'not-active'}>Wardrobe Accessories</button>
                    <button onClick={() => filterByCategory('Tough-end Glass Fittings')} className={selectedCategory === 'Tough-end Glass Fittings' ? 'active' : 'not-active'}>Tough-end Glass Fittings</button>
                    <button onClick={() => filterByCategory('Digital Locks')} className={selectedCategory === 'Digital Locks' ? 'active' : 'not-active'}>Digital Locks</button>
                    <button onClick={() => filterByCategory('Kitchen Applicances')} className={selectedCategory === 'Kitchen Applicances' ? 'active' : 'not-active'}>Kitchen Applicances</button>
                    <button onClick={() => filterByCategory('Shelfing Accessories')} className={selectedCategory === 'Shelfing Accessories' ? 'active' : 'not-active'}>Shelfing Accessories</button>
                </div>
                <div className="row">
                    {filteredArray.length === 0 ? (
                        <div className='no-products-message'>
                            <h3>No Products in this category.</h3>
                        </div>
                    ) : (
                        filteredArray.map(product => (
                            <div className="card" key={product.id}data-aos="fade" data-aos-duration="1000">
                                <Link to={`/products/${product.slug}`}>
                                    <div className="img-container">
                                        {product.acf.cover_image && (
                                            <img src={product.acf.cover_image.sizes.medium_large} alt={product.acf.name} />
                                        )}

                                    </div>
                                    <div className="content">
                                        <h3>{product.title.rendered}</h3>
                                    </div>
                                </Link>
                            </div>
                        ))
                    )}
                </div>

            </div>

        </div>
    )
}

export default ProductList