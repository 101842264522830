import React from 'react'
import '../../../styles/BannerSection.css'

function BannerSection(props) {
    const { h2, imgUrl } = props;
    return (
        <div className='BannerSection'>
            <div className="img-container">
                <img src={imgUrl} alt="about banner" />
            </div>
            <div className="overlay"></div>
            <div className="text">
                <h2>{h2}</h2>
            </div>
        </div>
    )
}

export default BannerSection