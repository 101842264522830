import React from 'react'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Helpers/BannerData';
import Navbar from '../Components/General_cmpnts/Navbar/Navbar';
import Overview from '../Components/AboutPage/Overview/Overview';
import Mission from '../Components/AboutPage/Mission/Mission';
// import Team from '../Components/AboutPage/Team/Team';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import { Helmet } from 'react-helmet';

function About() {
    const { AboutH2, AboutImg } = BannerData;

    return (
        <div className='About'>
            <Helmet>
                <title>About - WhiteField Gallery</title>
                <meta name="description" content="Discover the history of Whitefield Door Locks – dedicated to crafting high-quality, secure solutions for your peace of mind. Explore our journey now." />
                <meta property="og:locale" content="en_US"></meta>
                <meta property="og:type" content="website"></meta>
                <meta property="og:title" content="About - WhiteField Gallery"></meta>
                <meta property="og:description" content="Discover the history of Whitefield Door Locks – dedicated to crafting high-quality, secure solutions for your peace of mind. Explore our journey now."></meta>

            </Helmet>
            <Navbar />
            <BannerSection h2={AboutH2} imgUrl={AboutImg} />
            <Overview />
            <Mission />
            {/* <Team /> */}
            <Footer />
        </div>

    )
}

export default About