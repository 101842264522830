import React from 'react'
import '../../../styles/Herosection.css'
import Navbar from '../../General_cmpnts/Navbar/Navbar'

function Herosection() {
    return (
        <div className='Herosection'>
            <Navbar/>
            <div className="container">
                <div className="row">
                    <div className="image-container first"data-aos="fade-left"data-aos-duration="1000" data-aos-delay="0">
                        <div className="explore">
                            <h3>Secure Your Space with Confidence</h3>
                        </div>
                        <img src="img/herosection/herosection-2.jpg" alt="herosection 1" />
                    </div>
                    <div className="image-container second"data-aos="fade-left"data-aos-duration="1000" data-aos-delay="300">
                        <div className="explore">
                            <h3>Advanced Security for Peace of Mind</h3>
                        </div>
                        <img src="img/herosection/herosection-8.jpg" alt="herosection 2" />
                    </div>
                    <div className="image-container third"data-aos="fade-left"data-aos-duration="1000" data-aos-delay="600">
                        <div className="explore">
                            <h3>Effortless Access, Uncompromised Safety</h3>
                        </div>
                        <img src="img/herosection/herosection-10.jpg" alt="herosection 3" />
                    </div>
                    <div className="image-container fourth" data-aos="fade-left"data-aos-duration="1000" data-aos-delay="900">
                        <div className="explore">
                            <h3>Elegance Meets Unparalleled Security</h3>
                        </div>
                        <img src="img/herosection/herosection-6.jpg" alt="herosection 4" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Herosection