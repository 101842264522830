import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/GalleryList.css';
import LightGallery from 'lightgallery/react';
import LGFullscreen from 'lightgallery/plugins/fullscreen';
import lgShare from 'lightgallery/plugins/share';
import lgZoom from 'lightgallery/plugins/zoom';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-autoplay.css';
import lozad from 'lozad';


import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

function GalleryList({ gallery }) {
  const [mediaItems, setMediaItems] = useState([]);
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Initialize Plyr for each video element
    videoRef.current = Array.from({ length: mediaItems.length }, () => React.createRef());
  }, [mediaItems]);
  useEffect(() => {

    const extractMediaLinksFromContent = (content) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const mediaElements = doc.querySelectorAll('img, video');

      const mediaLinks = Array.from(mediaElements).map((media, index) => ({
        id: index,
        type: media.tagName.toLowerCase(),
        src: media.tagName.toLowerCase() === 'img' ? media.getAttribute('src') : media.querySelector('source').getAttribute('src'),
      }));

      return mediaLinks;
      setLoading(false);

    };

    if (gallery && gallery.acf && gallery.acf.gallery) {
      const mediaLinks = extractMediaLinksFromContent(gallery.acf.gallery);
      setMediaItems(mediaLinks);
      setLoading(false);

    }

  }, [gallery]);


  useEffect(() => {
    const observer = lozad('.lozad', {
      loaded: function (el) {

        el.classList.add('lazy-loaded');
        el.addEventListener('load', () => {
          el.classList.remove('skeleton-loader');
        });
      },
      rootMargin: '-20px 0px',
    });
    observer.observe();

    return () => {

    };
  }, []);

  if (loading) {
    return (
      <div className="spinning_loading">
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  const renderLightGallery = () => {
    const imageItems = mediaItems.filter((media) => media.type === 'img');

    return (
      <div className='image-gallery'>
        <LightGallery
          plugins={[LGFullscreen, lgShare, lgZoom, lgAutoplay]}
          videojs={false} // Set videojs to false
        >
          {imageItems.map((media) => (
            <a className="media-container" href={media.src} key={media.id} data-sub-html="">
              <img alt={`media-${media.id}`} src={media.src}className='lozad skeleton-loader' />
            </a>
          ))}
        </LightGallery>
      </div>
    );
  };

  const renderVideos = () => {
    const videoItems = mediaItems.filter((media) => media.type === 'video');

    if (videoItems.length === 0) {
      return null;
    }

    return (
      <div className='video-gallery'>
        <h2>Videos</h2>
        <div className="row">
          {videoItems.map((media) => (
            <video ref={videoRef} controls playsInline key={media.id}>
              <source src={media.src} type="video/mp4" />
            </video>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className="GalleryList">
      {gallery && (
        <div className="container">
          {gallery.acf && gallery.acf.gallery && (
            <>
              {renderLightGallery()}
              {renderVideos()}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default GalleryList;
