import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Helpers/BannerData'
import GetInTouch from '../Components/ContactPage/GetInTouch/GetInTouch';
import Map from '../Components/ContactPage/Map/Map';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import { Helmet } from 'react-helmet';

function Contact() {
    const { ContactH2, ContactImg } = BannerData;
    return (
        <div className='Contact'>
            <Helmet>
                <title>Contact - WhiteField Gallery</title>
                <meta name="description" content="Get in touch with Whitefield Door Locks for top-notch door security solutions. Our experts are ready to assist you. Secure your space with confidence." />
                <meta property="og:locale" content="en_US"></meta>
                <meta property="og:type" content="website"></meta>
                <meta property="og:title" content="Contact - WhiteField Gallery"></meta>
                <meta property="og:description" content="Get in touch with Whitefield Door Locks for top-notch door security solutions. Our experts are ready to assist you. Secure your space with confidence."></meta>
            </Helmet>
            <Navbar />
            <BannerSection h2={ContactH2} imgUrl={ContactImg} />
            <GetInTouch />
            <Map />
            <Footer />

        </div>
    )
}

export default Contact