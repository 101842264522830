import React from 'react'
import Herosection from '../Components/HomePage/Herosection/Herosection'
import AboutIntro from '../Components/HomePage/AboutIntro/AboutIntro'
import FeaturedProducts from '../Components/HomePage/FeaturedProducts/FeaturedProducts'
import Testimonials from '../Components/HomePage/Testimonials/Testimonials'
import Footer from '../Components/General_cmpnts/Footer/Footer'
import { Helmet } from 'react-helmet'


function Home() {
  return (
    <div className='Home'>
      <Helmet>
        <title>Whitefield Gallery | Kozhikode</title>
        <meta name="description" content="Elevate your security with Whitefield Door Locks – where craftsmanship meets cutting-edge technology. Discover high-security locks for lasting peace of mind." />
        <link rel="canonical" href="https://www.whitefieldindia.com/"></link>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Whitefield Gallery | Kozhikode"></meta>
        <meta property="og:description" content="Elevate your security with Whitefield Door Locks – where craftsmanship meets cutting-edge technology. Discover high-security locks for lasting peace of mind."></meta>
        <meta property="og:url" content="https://www.whitefieldindia.com/"></meta>
      </Helmet>
      <Herosection />
      <AboutIntro />
      <FeaturedProducts />
      <Testimonials />
      <Footer />
    </div>
  )
}

export default Home