import React from 'react'
import  '../../../styles/ProductsIntro.css'
// import Brochure from '../Brochure/Brochure'

function ProductsIntro() {
    return (
        <div className='ProductsIntro'>
            <div className="container">
                <p data-aos="fade" data-aos-duration="1000">
                    At Whitefield, our door lock products redefine security, seamlessly blending innovation with elegance. Our meticulously crafted locks embody cutting-edge technology and timeless design, ensuring the safeguarding of your property while enhancing the overall aesthetic of your living or working environment. Explore a range of innovative and reliable solutions that prioritize safety and style, from advanced smart locks to classic designs. Elevate your security standards with Whitefield Door Locks, where each product reflects the sophistication of modern living. Welcome to a new era of unparalleled quality, reliability, and unmatched elegance.

                </p>
                {/* <Brochure/> */}
            </div>
        </div>
    )
}

export default ProductsIntro