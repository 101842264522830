import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../styles/BlogList.css';
// import { useNetwork } from '../../Context/NetworkContext';
import { Link } from 'react-router-dom';
import { wordpressUrl } from '../../../Helpers/wordpressUrl';

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    const [month, year] = formattedDate.split(' ');
    return `${month}, ${year}`;
}

function BlogList() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [fetchedBlogIds, setFetchedBlogIds] = useState(new Set());
    const [page, setPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [filteredArray, setFilteredArray] = useState([]);
    // const {networkERR,setNetworkERR} = useNetwork();

    const perPage = 100;
    const lastPageIndex = page * perPage;

    const fetchBlogs = () => {
        axios.get(`${wordpressUrl}wp-json/wp/v2/blog?per_page=${perPage}&page=${page}`)
            .then((res) => {
                const newBlogs = res.data.filter((blog) => !fetchedBlogIds.has(blog.id));
                setBlogs((prevBlogs) => [...prevBlogs, ...newBlogs]);
                setFilteredArray((prevBlogs) => [...prevBlogs, ...newBlogs]);
                newBlogs.forEach((blog) => fetchedBlogIds.add(blog.id));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                // setNetworkERR(true)
            });
    };

    useEffect(() => {
        fetchBlogs();
    }, [page]);



    const handleLoadMore = () => {
        setPage((prevPage) => prevPage + 1);
        fetchBlogs(); // Fetch new blogs when the page button is clicked
    };

    if (loading) {
        return (
            <div className='loading-container'>
                <div class="lds-grid">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }


    return (
        <div className='BlogList'>
            {/* <div className="category-buttons">
                <button onClick={() => filterByCategory('All')} className={selectedCategory === 'All' ? 'active' : 'not-active'}>All</button>
                <button onClick={() => filterByCategory('Digital Marketing')} className={selectedCategory === 'Digital Marketing' ? 'active' : 'not-active'}>Digital Marketing</button>
            </div> */}
            <div className="container">
                <div className="row">
                    {filteredArray.length === 0 ? (
                        <p className='no-blogs'>No Blogs available</p>
                    ) : (
                        filteredArray.map(blog => (
                            <div className="card" key={blog.id}>
                                <Link to={`/blogs/${blog.slug}`}>
                                    <div className="img-container">
                                        {blog.acf.cover_image &&
                                            <img src={blog.acf.cover_image.sizes.medium} alt={blog.acf.name} />
                                        }
                                    </div>
                                    <div className="content">
                                        {blog.title.rendered && <Link to={`/blogs/${blog.slug}`}>{blog.title.rendered}</Link>}
                                        <p>By {blog.acf.edited_by} | {formatDate(blog.modified)}</p>
                                    </div>
                                </Link>
                            </div>
                        ))
                    )}
                </div>
                <div className="load_more">
                    {filteredArray.length === lastPageIndex && <button className='loadMore_btn' onClick={handleLoadMore}>Load More</button>}
                </div>
            </div>
        </div>
    );
}

export default BlogList;
