import React from 'react'
import Navbar from '../Components/General_cmpnts/Navbar/Navbar'
import BannerSection from '../Components/General_cmpnts/BannerSection/BannerSection'
import BannerData from '../Helpers/BannerData'
import BlogList from '../Components/BlogPage/BlogList/BlogList';
import BlogIntro from '../Components/BlogPage/BlogIntro/BlogIntro';
import Footer from '../Components/General_cmpnts/Footer/Footer';
import { Helmet } from 'react-helmet';


function Blogs() {
  const { BlogH2, BlogImg } = BannerData;
  return (
    <div className='Blogs'>
      <Helmet>
        <title>Blogs - WhiteField Gallery</title>
        <meta name="description" content="Stay updated with door security trends. Explore insightful blogs by Whitefield Door Locks for expert advice and solutions for secure living." />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="article"></meta>
        <meta property="og:title" content="Blogs - WhiteField Gallery"></meta>
        <meta property="og:description" content="Stay updated with door security trends. Explore insightful blogs by Whitefield Door Locks for expert advice and solutions for secure living."></meta>

      </Helmet>
      <Navbar />
      <BannerSection h2={BlogH2} imgUrl={BlogImg} />
      <BlogIntro />
      <BlogList />
      <Footer />


    </div>
  )
}

export default Blogs